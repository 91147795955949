<template>
    <div id="Epg" class="container epg-page">
        <div class="epg-main-container">
            <div class="epg-header-container">
                <div class="main-title">
                    <h1 class="cat-title font-24">{{$t("Program Guide")}}</h1></div>
                <div class="date-time">
                    <h1 class="cat-title font-24">{{ upDateTime.time }}</h1>
                    <span>{{ upDateTime.date }}</span>
                </div>
            </div>
            <div class="callender-conatiner">
                <CallenderContainer  @actSelectDate="actSelectDate"></CallenderContainer>
            </div>
            <div class="data-container">
                <div class="epg-channel-container box-shadow">
                    <ChannelContainer :channelsData="channelsData" @actSelectedChannel="actSelectedChannel" v-if="channelsData"></ChannelContainer>
                    <div v-else class="epg-channel-wrapper load-wrapper">
                        <!-- Loading... -->
                        <WaveLoader :loaderHeight="loaderHeight"/>
                    </div>
                </div>
                <div class="epg-programme-main-container" id="epg-programme-container-id">
                    <ProgramContainer :activeProgramData="activeProgramData" :activeChannelData="activeChannelData" :selectedChannelIndex="selectedChannelIndex" @actSelectedProgram="actSelectedProgram"/>
                </div>
            </div>
        </div>
        <div v-if="loading" class="main-loader-layer">
            <div class="inner-loader-layer">
                <DotFlashing />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { eventBus } from "@/eventBus";
import moment from "moment";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";

export default {
    name: 'Epg',
    components: {
        WaveLoader: () => import(/* webpackChunkName: "waveLoader" */ "@/components/Loaders/WaveLoader.vue"),
        ProgramContainer: () => import(/* webpackChunkName: "programContainer" */ "@/components/Epg/EpgProgramContainer.vue"),
        CallenderContainer: () => import(/* webpackChunkName: "callenderContainer" */ "@/components/Epg/CallenderContainer.vue"),
        ChannelContainer: () => import(/* webpackChunkName: "channelContainer" */ "@/components/Epg/ChannelContainer.vue"),
        DotFlashing: () => import (/* webpackChunkName: "dotFlashing" */ "@/components/Loaders/DotFlashing.vue")
    },
    data() {
        return{
            finalData: null,
            channelsData: null,
            programsData: null,
            upDateTime: {
                date: '00-00-0000',
                time: '0:00'
            },
            weekArray: [],
            selectedChannelIndex: 0,
            selectedDateIndex: 1,  
            loaderHeight: "2.5rem",
            localDisplayLang: null,
            loading: false,
            placeholder_lands: require('@/assets/placeholder-lands.svg'),
            activeProgramData: null,
            activeChannelData: null
        }
    },
    computed: {
        ...mapGetters(["subscriberid", "profileid"]),
    },
    created() {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    },
    mounted() {
        let date = (new Date()).toISOString().split('T')[0];
        this.loadScript("https://static-templates.web.app/static/xmlToJson.js")
        .then(async (data) => {
            if(data.loaded) {
                await this.fetchEpgXmlData(date);
                this.doDate();
                setInterval(() => {
                    this.doDate();
                    document.getElementById('currentProgram') &&  document.getElementById('currentProgram').scrollIntoView();
                }, 1000*60);
            }
            })
        .catch((error) => {
            console.error(error);
        });

    },
    mixins: [PlaybackUtilities],
    methods: {
        ...mapActions(['mapEpgXmlData', 'getContent']),
        async fetchEpgXmlData(date) {
            const payload = {};
            // payload.start = "2023-02-13 00:00:00";
            // payload.stop =  "2023-02-13 23:59:59";
            let s = moment(date+" 00:00:00").utc().format("YYYY-MM-DD HH:mm:ss");
            let e = moment(date+" 24:00:00").utc().format("YYYY-MM-DD HH:mm:ss");

            payload.start = s;
            payload.stop =  e;
            payload.localDisplayLang = this.localDisplayLang,
            this.finalData = null;

            /* delete me */
            // payload.start = '2023-06-02%2000%3A00%3A00';
            // payload.stop =  '2023-06-02%2023%3A59%3A59';
            /* delete me end */


           
            // let res = await this.mapEpgXmlData(payload);
            this.mapEpgXmlData(payload).then((res)=>{
                this.finalData = xmlToJson.parse(res.data);
                if(this.finalData.tv) {
                    this.channelsData  = this.finalData.tv.channel;
                    this.programsData =  this.finalData.tv.programme ? this.finalData.tv.programme : null;
                    this.actSelectedChannel(this.channelsData[0], 0);
                }
            }).catch((err)=>{
                this.finalData = [];
            })
            setTimeout(() => {
                document.getElementById('live-item-catchup') &&  document.getElementById('live-item-catchup').scrollIntoView()

            }, 1000);
        },

        doDate() {
            var days = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
            var months = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
            let now = new Date();
            let hours = '0';
            var today = days[now.getDay()] + now.getSeconds();
            this.upDateTime.date = now.getDate() + "-" + (now.getMonth() + 1) + "-" + now.getFullYear();
            let type = ['AM', 'PM'];
            let timeType;
            let min = '00';
            if(now.getHours() > 12){
                hours = now.getHours() - 12;
                timeType = type[1]
            } else {
                hours = now.getHours();
                timeType = type[0]
            }
            if(now.getMinutes() < 10) {
                min = '0' + now.getMinutes();
            } else {
                min = now.getMinutes();
            }
            this.upDateTime.time = hours +":" + min + " " + timeType;
        },

        actSelectedChannel(channel, i) {
            this.activeProgramData = null;
            this.selectedChannelIndex = i;
            this.activeChannelData = channel;
            this.sortedProgramsData(channel).then((res)=> {
                if(res.length > 0) {
                    this.activeProgramData = res.filter((item, index, self) => {
                        return index === self.findIndex((t) => (
                            t.channel === item.channel &&
                            t.desc === item.desc &&
                            t.start === item.start &&
                            t.stop === item.stop &&
                            t.title === item.title
                        ))
                    });
                } else {
                    this.activeProgramData = [];
                }
            }).catch((err)=>{this.activeProgramData = []})
        },
        sortedProgramsData(channel) {
            let filterData = [];
            return new Promise((resolve, reject) => {
                try {
                    let singleData = this.programsData?.filter((item, i) => {
                        return item?.channel == channel?.id;
                    });
                    if(singleData) {
                        filterData = singleData?.sort((a, b) => (moment.utc(moment(a.start, "YYYYMMDDHHmmss Z")).local().valueOf()) - (moment.utc(moment(b.start, "YYYYMMDDHHmmss Z")).local().valueOf()));
                    }
                    resolve(filterData);
                } catch (error) {
                    resolve(filterData);
                }
            })
        },
        actSelectDate(date) {
            this.channelsData = null;
            this.activeProgramData = null;
            this.fetchEpgXmlData(date);
        },

        actSelectedProgram(condition) {
            if(condition){
                this.loading = true;
            } else {
                this.loading = false;
            }
        },

        actUpDownArrow(type) {
            if(type === 'up') {
                // alert(type);
            } else {
                // alert(type);
            }
        },

        loadScript (src, async = true, type = "text/javascript") {
        return new Promise((resolve, reject) => {
            try {
            const tag = document.createElement("script");
            const container = document.head || document.body;

            tag.type = type;
            tag.async = async;
            tag.src = src;

            tag.addEventListener("load", () => {
                resolve({ loaded: true, error: false });
            });

            tag.addEventListener("error", () => {
                reject({
                loaded: false,
                error: true,
                message: `Failed to load script with src ${src}`,
                });
            });

            container.appendChild(tag);
            } catch (error) {
            reject(error);
            }
        });
        },

        getImageSrc(src) {
            return src ? `${src}` : this.placeholder_lands;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./Epg.scss";

.active {
    background: $primary-btn-color;
    color: #FFFFFF !important;
}
.user-img, .cast-name {
  pointer-events: none;
}
.user-item:hover:not(.active) {
  background-color: black;
}
// .d-none {
//     display: none;
// }

.main-loader-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%;
    left: 0%;
    // transform: translate(-50%, -50%);
    background: rgba(49, 49, 49, 0.726);
    border: 0.1rem #262626 solid;
    width: 100vw;
    height: 100vh;
}
.inner-loader-layer {
    background: rgba(8, 8, 8, 0.726);
    border: 0.1rem #262626 solid;
    width: 10rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>